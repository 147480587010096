<template>
    <div class="order-info">
        <!-- 标题 -->
        <div class="order-info-title">
            确认订单信息
        </div>
        <!-- 内容 -->
        <div class="order-info-content">
            <!-- 预览图 -->
            <el-image v-if="metopeOrder.goodsImg"
                :src="metopeOrder.goodsImg"
                fit="scale-down"
                class="order-info-content-pic">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
            <!-- 描述 -->
            <div class="order-info-content-desc">
                <!-- 订单编号 -->
                <div>
                    <span>订单编号：</span>
                    <span>{{metopeOrder.orderID}}</span>
                </div>
                <!-- 下单日期 -->
                <div>
                    <span>下单日期：</span>
                    <span>{{metopeOrder.createTime}}</span>
                </div>
                <!-- 名称 -->
                <slot name='name'
                    :data="metopeOrder">
                    <div>
                        <span>名称：</span>
                        <span>{{metopeOrder.goodsName}}</span>
                    </div>
                </slot>
                <!-- 类型 -->
                <slot name='type'
                    :data="metopeOrder">
                    <div>
                        <span>墙面定制类型：</span>
                        <span>{{metopeOrder.goodsType}}</span>
                    </div>
                </slot>
                <!-- 材质 -->
                <!-- <div>
                    <span>材质：</span>
                    <span>{{metopeOrder.goodsMaterial}}</span>
                </div> -->
                <!-- 单价 -->
                <div>
                    <span>单价：</span>
                    <span class="order-info-content-desc-price">{{metopeOrder.unitPrice || metopeOrder.price}}</span>
                    <span>{{`元${metopeOrder.unit}`}}</span>
                    <span v-if="metopeOrder.count">{{` x ${metopeOrder.count}`}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            metopeOrder: Object
        }
    }
</script>

<style scoped>
.order-info{
    padding: 30px 80px;
    background-color: #fff;
    border-radius: 6px;
}
/* 标题 */
.order-info-title{
    margin-bottom: 20px;
    letter-spacing: 2px;
    font-size: 16px;
}
/* 内容 */
.order-info-content{
    display: flex;
    align-items: stretch;
}
/* 图片 */
.order-info-content-pic{
    width: 200px;
    height: 200px;
}
.order-info-content-desc{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    letter-spacing: 2px;
}
.order-info-content-desc-price{
    font-size: 18px;
    color: var(--color);
}
</style>